<template>
<section class="hero is-medium has-another-background">
  <div class="hero-body has-text-centered">
  <img src="../../assets/get-one.png">
  </div>
</section>
  <div>
    <Mint />
  </div>

</template>

<script>
import Mint from "../../components/Mint.vue";
export default {
  name: "home",
  components: {
    Mint,
  },
};
</script>
<style scoped>
</style>