<template>
  <div class="mint">
    <div class="mint-inner">
      <div v-if="!minted">
        <button
          class="btn-mint"
          :class="signed_in ? 'active' : ''"
          v-on:click="signIn()"
        >
          <div class="container">
            <span class="label" v-if="!signed_in" style="color: #000;">CONNECT WALLET</span>
            <span class="label" v-else>WALLET CONNECTED</span>
          </div>
        </button>
      </div>
      <div v-if="signed_in">
        <div v-if="wrongNetwork">
          <p class="mt-3">Please connect to the correct network</p>
        </div>
        <div v-else-if="!sale_started">
          <p class="mt-3">SALE NOT STARTED</p>
          <p class="mt-3">Price 0.05Ξ</p>
        </div>
        <div v-else-if="total_supply === '5000'">
          <p>SOLD OUT</p>
          <a
            href="https://opensea.io/"
            target="_blank"
            class="mt-1 button is-link"
          >
            Buy on OpenSea
          </a>
        </div>
        <div v-else>
          <div v-if="!is_minting && !minted && !has_failed" class="mt-3">
            <div>{{ total_supply }} / 5000 total minted</div>
            <!-- <p class="mt-3">Price 0.05Ξ</p> -->
            <p class="mt-3">Price 0.05 ETH</p>
            <!-- <p class="mt-3">(OG HOLDERS ONLY!)</p> -->

            <div style="max-width: 480px; margin: 0 auto">

              <span style="display: inline-block; width: 33%; text-align: center;">
                <p class="mt-3" style="margin: 0; padding: 0;">How many?</p>
              </span>
              <span style="display: inline-block; width: 33%; text-align: center;">
              <!-- <div class="field is-grouped mt-3 fully-center"> -->
                <p class="control is-expanded" style="margin: 0; padding: 0;">
                  <input
                    class="input"
                    type="number"
                    v-model="how_many_tokens"
                    placeholder="How many do you want to mint?"
                    style="width: 70px; position: relative; top: -10px;"
                  />
                </p>
              </span>
              <span style="display: inline-block; width: 33%; text-align: center;">
                <p class="control" style="margin: 0; padding: 0;">
                  <button class="btn-mint" v-on:click="mintToken()">
                    <div class="container">
                      <span class="label" style="color: #000;">MINT</span>
                    </div>
                  </button>
                </p>
              </span>
            </div>

            <p class="mt-3 has-text-danger" v-if="user_error">
              {{ user_error_message }}
            </p>
          </div>
          <div v-else-if="!minted && !has_failed" class="mt-3">
            <p>Minting...</p>
            <progress class="progress is-small is-primary mt-3" max="100">
              15%
            </progress>
          </div>
          <div
            class="notification is-link mt-4"
            v-if="!minted && transaction_hash != null"
          >
            Transaction created :
            <a
              target="_blank"
              :href="`https://etherscan.io/tx/${transaction_hash}`"
              >View on Etherscan</a
            >
          </div>
          <div v-else-if="minted">
            <div class="notification is-success mt-5">
              YARRR MATEY! You have minted your POTOS!
            </div>
            <a
              class="btn-mint mt-2"
              href="https://opensea.io/"
              target="_blank"
            >
              <div class="container">
                <span class="label" style="color: #000;">View on OpenSea</span>
              </div>
            </a>
          </div>
          <div class="notification is-warning mt-5" v-else-if="has_failed">
            {{ error_message }}
            <a v-on:click="reset()">Reset</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Web3 from "web3";
import { ADDRESS, ABI } from "../../config.js";
export default {
  name: "Mint",
  data() {
    return {
      signed_in: false,
      is_minting: false,
      wallet_address: null,
      how_many_tokens: 1,
      contract: null,
      contract_address: null,
      total_supply: 0,
      sale_started: false,
      token_price: 0.05,
      transaction_hash: null,
      minted: false,
      last_receipt: null,
      has_failed: false,
      wrongNetwork: false,
      error_message: "",
      user_error: false,
      user_error_message: ""
    };
  },
  mounted: function () {
    this.$nextTick(function () {
      this.callContractData();
    });
  },
  methods: {
    reset() {
      this.has_failed = false;
    },
    async signIn() {
      if (this.signed_in) {
        return;
      }

      if (typeof window.web3 !== "undefined") {
        // Use existing gateway
        window.web3 = new Web3(window.ethereum);
      } else {
        // eslint-disable-next-line no-console
        console.log(
          "No Ethereum interface injected into browser. Read-only access"
        );
      }

      if (window.ethereum) {
        let self = this;
        try {
          const accounts = await window.ethereum.request({
            method: "eth_requestAccounts",
          });
          window.web3.eth.net.getNetworkType().then((network) => {
            if (network != process.env.VUE_APP_ETHNETWORK) {
              self.wrongNetwork = true;
            }
          });

          let wallet = accounts[0];
          self.signed_in = true;
          self.wallet_address = wallet;
          self.callContractData();
        } catch (error) {
          if (error.code === 4001) {
            // User rejected request
          }

          // eslint-disable-next-line no-console
          console.log(error);
        }
      }
    },
    async callContractData() {
      if (typeof window.web3 !== "undefined") {
        // Use existing gateway
        window.web3 = new Web3(window.ethereum);
      } else {
        // eslint-disable-next-line no-console
        console.log(
          "No Ethereum interface injected into browser. Read-only access"
        );
      }

      this.contract = new window.web3.eth.Contract(ABI, ADDRESS);
      this.sale_started = await this.contract.methods.saleIsActive().call();
      this.total_supply = await this.contract.methods.totalSupply().call();
      this.token_price = await this.contract.methods.PRICE().call();
    },
    async mintToken() {
      if (this.contract) {
        if (this.how_many_tokens > 5) {
          this.user_error_message = "Max purchase is 5";
          this.user_error = true;
          return;
        }
        // let addr = this.wallet_address.toLowerCase();
        // if (this.valid_wallets.indexOf(addr) === -1) {
        //   this.user_error_message = "Arr, you're not on the list matey!  Sorry!";
        //   this.user_error = true;
        //   return;
        // }
        this.is_minting = true;
        this.has_failed = false;
        this.user_error = false;

        this.transaction_hash = null;
        let self = this;
        const price = Number(this.token_price) * this.how_many_tokens;
        const gasAmount = await this.contract.methods
          .mint(this.how_many_tokens)
          .estimateGas({ from: this.wallet_address, value: price })
          .catch(function (error) {
            self.has_failed = true;
            self.is_minting = false;
            if (error.message.includes("supply exceeded")) {
              self.error_message =
                "Sorry matey, max supply exceeded!";
            } else if (
              error.message.includes("Invalid mint amount!")
            ) {
              self.error_message = "Sorry matey, that be too many!";
            } else {
              self.error_message = error.message;
            }
          })
        if(self.has_failed) return false;
        this.contract.methods
          .mint(this.how_many_tokens)
          .send({
            from: this.wallet_address,
            value: price,
            gas: parseInt(1.2 * gasAmount),
          })
          .on("transactionHash", function (hash) {
            self.transaction_hash = hash;
          })
          .on("error", function () {
            self.is_minting = false;
          })
          .then(function (receipt) {
            self.is_minting = false;
            self.minted = true;
            self.last_receipt = receipt;
          });
      } else {
        // eslint-disable-next-line no-console
        console.log("Wallet not connected");
      }
    },
  },
};
</script>